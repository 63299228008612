import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '../shared/common';
import { RequestHelper } from '../shared/requesthelper';
import { GlobalsService } from '../shared/globals';
import { Constants } from '../shared/constants';
import { timeout } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class GarageService {

  constructor(public http: HttpClient,
    public commonService: CommonService,
    public requestHelper: RequestHelper, ) { }
  getAllGarages(controlType) {
    return new Promise((resolve, reject) => {
      const url = this.requestHelper.GetUrlForAllGarages(controlType);
      const reqHeaders = this.requestHelper.getRequestHeader();
      this.http.get(url, { headers: reqHeaders })
        .pipe(timeout(Constants.TIMEOUT))
        .subscribe(
          (data: any) => {
            resolve(data.Garages);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  getUserMappedGarages(controlType) {
    return new Promise((resolve, reject) => {
      const url = this.requestHelper.GetUrlForUserGarages(controlType);
      const reqHeaders = this.requestHelper.getRequestHeader();
      this.http.get(url, { headers: reqHeaders })
        .pipe(timeout(Constants.TIMEOUT))
        .subscribe(
          (data: any) => {
            resolve(data.Garages);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  saveGarage(body) {
    return new Promise((resolve, reject) => {
      const url = this.requestHelper.PostUrlForSaveGarage();
      const reqHeaders = this.requestHelper.getRequestHeader();
      this.http.post(url, body,{ headers: reqHeaders } )
        .pipe(timeout(Constants.TIMEOUT))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  deleteGarageData(garageID) {
    return new Promise((resolve, reject) => {
      const url = this.requestHelper.deleteUrlForGarage(garageID);
      const reqHeaders = this.requestHelper.getRequestHeader();
      this.http.get(url, { headers: reqHeaders })
        .pipe(timeout(Constants.TIMEOUT))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  public saveActivityHistory(body) {
    const url = this.requestHelper.GetUrlForSaveActivityHistory();
    const reqHeaders = this.requestHelper.getRequestHeader();
    const postBody = body;
    return new Promise((resolve, reject) => {
      this.http.post(url, body,{ headers: reqHeaders } )
      .pipe(timeout(Constants.TIMEOUT))
      .subscribe(
        (data: any) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );


    });
}
public CallParticleAPI(body) {
  const url = this.requestHelper.GetUrlForCallParticleAPI();
  const reqHeaders = this.requestHelper.getRequestHeader();
  const postBody = body;
  return new Promise((resolve, reject) => {
    this.http.post(url, body,{ headers: reqHeaders } )
    .pipe(timeout(Constants.TIMEOUT))
    .subscribe(
      (data: any) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );


  });
}
 getGarageMappedUsers(controlItemID) {
      return new Promise((resolve, reject) => {
        const url = this.requestHelper.GetUrlForGarageMappedUsers(controlItemID);
        const reqHeaders = this.requestHelper.getRequestHeader();
      this.http.get(url,{ headers: reqHeaders }  )
          .pipe(timeout(Constants.TIMEOUT))
          .subscribe(
            (data: any) => {
              resolve(data.users);
            },
            (error) => {
              reject(error);
            }
          );
      });
    }
    getGarageTypes() {
      return new Promise((resolve, reject) => {
        const url = this.requestHelper.GetUrlForGarageType();
        const reqHeaders = this.requestHelper.getRequestHeader();
      this.http.get(url,{ headers: reqHeaders }  )
          .pipe(timeout(Constants.TIMEOUT))
          .subscribe(
            (data: any) => {
              resolve(data.GarageTypes);
            },
            (error) => {
              reject(error);
            }
          );
      });
    }
    saveGarageMappedUsers(body) {
      return new Promise((resolve, reject) => {
        const url = this.requestHelper.PostUrlForSaveGarageMappedUsers();
        const reqHeaders = this.requestHelper.getRequestHeader();
        this.http.post(url, body,{ headers: reqHeaders } )
          .pipe(timeout(Constants.TIMEOUT))
          .subscribe(
            (data: any) => {
              resolve(data);
            },
            (error) => {
              reject(error);
            }
          );
      });
    }
}
