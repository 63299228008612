export enum messages {
    MESSAGETITLE = 'Information',
    SERVERERRORTITLE = 'Server Error',
    INVALIDCREDENTIALS = 'Invalid Username or Password.',
    NONETWORK = 'No mobile or wifi network found. Please, connect to a network and try again.',
    ENTERUSERNAME = 'Please, enter Username.',
    ENTERPASSWORD = 'Please, enter Password.',
    SERVERERROR = 'Please, try again or contact Customer Service for assistance. Thank you.',
    ALERTTITLINFORMATION = 'Information',
    PLEASECONNECTTONETWORK = 'Please, connect to a network',
    VALIDATINGUSER="Validating user..",
    GETORGANIZATIONDATA="Loading Organizations..",
    ORGNAME="Please, enter organization name.",
    ALLUSERS="Loading users..",
    LOADINGGARAGES="Loading garages..",
    SAVEORG="Saving Organization..",
    DELETEORGDATA="Deleting Organization..",
    SAVEUSER="Saving user..",
    SAVEGARAGE="Saving garage..",
    FIRSTNAME="First Name is mandatory",
    LASTNAME="Last Name is Mandatory",
    EMAIL="Email is Mandatory",
    PASSWORD="Password is mandatory",
    ORGID="Organization is mandatory",
    DELETECONFIRM="Would you like to Delete?",
    GARAGEID="Garage ID is mandatory.",
    GARAGENAME = "Garage Name is mandatory",
    ALLGARAGES="Loading all garges",
    ALLACTIVITYHISTORY="Loading all activities",
    CANNOTDELETELOGGEDINUSER="Cannot delete loggedin user",
    EDITINPROFILE="Please, edit your profile under Profile.",
    GARAGETIMEMANDATORY="Please, enter the time.",
    ACCESSRESTRICTED="Access is restricted.",
    LATITUDEMANDATORY = "Latitude is mandatory",
    LONGITUDEMANDATORY = "Longitude is mandatory",
    GEOFENCEMANDATORY = "GeoFence Distance is mandatory",
    GEOFENCELIMIT = "You can only enter a Geofence of 1000 meters or less",
    FREEZEDESCRIPTIOMMANDATORY = " Enter the reason."
  };
  export enum Constants {
    //serviceURL='https://techprocontrolapi-nf2.conveyor.cloud/api',//dev cloud
    //serviceURL='http://10.211.55.9:45457/api',//dev local
    //serviceURL='https://controlapi.techpro.com/api',//prod
    serviceURL='https://controlapi-dev.techpro.com/api',//staging
    TIMEOUT=30000,
    VersionNo='1.3',
    IsDEV=0,
    IsApiForCallParticle=1,
  }
  export class MenuDetails {

    public menuItems=[
        {
            "Id": "dashboard",
            "Title": "Dashboard",
            "Icon": "home",
            "Selected": false,
            "ContentUrl": "dashboard",
            "Params": {
                "Title": "Dashboard"
              }
          },
          {
            "Id": "organization",
            "Title": "Organization",
            "Icon": "home",
            "Selected": false,
            "ContentUrl": "organization",
            "Params": {
                "Title": "Organization"
              }
          },
          {
            "Id": "groups",
            "Title": "Groups",
            "Icon": "home",
            "Selected": false,
            "ContentUrl": "groups",
            "Params": {
                "Title": "Groups"
              }
          },
          {
            "Id": "users",
            "Title": "Users",
            "Icon": "people-circle-outline",
            "Selected": false,
            "ContentUrl": "users",
            "Params": {
                "Title": "Users"
              }
          },
          {
            "Id": "activityhistory",
            "Title": "Activity History",
            "Icon": "people-circle-outline",
            "Selected": false,
            "ContentUrl": "activityhistory",
            "Params": {
                "Title": "Activity History"
              }
          },
    ]
    public menuControlItems=[
        {
            "Id": "garages",
            "Title": "Garages",
            "Icon": "home",
            "Selected": false,
            "ContentUrl": "garages",
            "Params": {
                "Title": "Garages"
              }
          }
    ]
  }