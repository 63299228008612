import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, AlertController } from '@ionic/angular';
import { CommonService } from '../../shared/common';
import { messages } from 'src/app/shared/constants';
import { GlobalsService } from '../../shared/globals';
import { GarageService } from '../../services/garage.service';
import * as Particle from '../../../assets/js/particle';
@Component({
  selector: 'app-addgaragemodal',
  templateUrl: './addgaragemodal.page.html',
  styleUrls: ['./addgaragemodal.page.scss'],
})
export class AddgaragemodalPage implements OnInit {
  public usersList = [];
  public tempUsersList=[];
  public userListOriginal =[];
  public searchTextVal='';
  public garageTypes=[];
  public garageData = {
    ControlItemID: 0, ControlItemName: '', DisplayName: '', ControlType: 'GARAGE', IsActive: true,
    IsDeleted: false, LoggedInEmail: '', Latitude: '', Longitude: '', GeoFenceDistance: 0, 
    IsFreezeGarage:false, IsCommercial:false, Description:'', GarageType:1
  }
  public garageMappedUserDataForSend = { ControlItemID: 0, UserIDs: '' }
  public tempOldGarageData:any;
  public selectedGarageType=1;
  constructor(public modalCtrl: ModalController,
    public navParams: NavParams,
    private commonService: CommonService,
    private globals: GlobalsService,
    private garageService: GarageService,
    private alertCtrl:AlertController) {
    this.garageData = navParams.get('data');
    this.garageTypes = navParams.get('garageTypes');
    this.selectedGarageType = this.garageData.GarageType;
  }

  ngOnInit() {
    this.garageData = Object.assign({}, this.navParams.get('data'));
    this.tempOldGarageData = Object.assign({}, this.garageData);
    this.commonService.assignGlobals();
    this.getGarageMappedUsers();
  }
  dismiss(flag) {
    this.modalCtrl.dismiss({
      'isCanceldismissed': flag
    });
  }
  onChangeRadioEvent($event) {
    console.log('status ' + $event.target.value);
    this.garageData.IsActive = $event.target.value;
  }
  onChangeGarageType($event) {
    console.log('garage type ' + $event.target.value);
    this.garageData.GarageType = $event.target.value;
  }
  getGaragtypeName(val){
      this.garageTypes.forEach(element => {
        if(element.value == val){
          return element.name;
        }
      });
  
  }
  async onSubmitGarageClick() {
    if (this.garageData.ControlItemName == "") {
      this.commonService.showAlert(messages.ALERTTITLINFORMATION, messages.GARAGEID, ['OK']);
      return;
    } else if (this.garageData.DisplayName == "") {
      this.commonService.showAlert(messages.ALERTTITLINFORMATION, messages.GARAGENAME, ['OK']);
      return;
    } else if (this.garageData.Latitude == "") {
      this.commonService.showAlert(messages.ALERTTITLINFORMATION, messages.LATITUDEMANDATORY, ['OK']);
      return;
    } else if (this.garageData.Longitude == "") {
      this.commonService.showAlert(messages.ALERTTITLINFORMATION, messages.LONGITUDEMANDATORY, ['OK']);
      return;
    } else if (this.garageData.GeoFenceDistance == undefined || this.garageData.GeoFenceDistance == null || this.garageData.GeoFenceDistance == 0) {
      this.commonService.showAlert(messages.ALERTTITLINFORMATION, messages.GEOFENCEMANDATORY, ['OK']);
      return;
    } else if (this.garageData.GeoFenceDistance > 1000) {
      this.commonService.showAlert(messages.ALERTTITLINFORMATION, messages.GEOFENCELIMIT, ['OK']);
      return;
    }
    if (navigator.onLine) {
      this.searchTextVal = '';
      this.filterList();
      const loader = await this.commonService.showLoading(messages.SAVEGARAGE);
      const body = this.garageData;
      this.garageData.LoggedInEmail = this.globals.userInfo.userName;
      if(this.garageData.IsFreezeGarage == this.tempOldGarageData.IsFreezeGarage){
        this.garageData.Description = '';
      }
      this.garageService
        .saveGarage(body)
        .then((data: any) => {
          loader.dismiss();
          if (data.Message == 'SUCCESS') {
            this.dismiss(false);
          }
        })
        .catch((e) => {
          loader.dismiss();
          console.log(e.error.text);
          this.commonService.showAlert(messages.ALERTTITLINFORMATION, e.error.text, ['OK']);
        });

      var tempUsersList = this.usersList.filter(item => item.IsNotifyEnabled == true);
      if (tempUsersList.length > 0) {

        for (var temp = 0; temp < tempUsersList.length; temp++) {
          if (tempUsersList.length == temp + 1) {
            this.garageMappedUserDataForSend.UserIDs = this.garageMappedUserDataForSend.UserIDs + tempUsersList[temp].UserID;
          } else {
            this.garageMappedUserDataForSend.UserIDs = this.garageMappedUserDataForSend.UserIDs + tempUsersList[temp].UserID + ',';
          }
        }
      }
      this.garageMappedUserDataForSend.ControlItemID = this.garageData.ControlItemID;
      const postBody = this.garageMappedUserDataForSend;
      this.garageService
        .saveGarageMappedUsers(postBody)
        .then((data: any) => {
          loader.dismiss();
          if (data.Message == 'SUCCESS') {
            this.dismiss(false);
          }
        })
        .catch((e) => {
          loader.dismiss();
          console.log(e.error.text);
          this.commonService.showAlert(messages.ALERTTITLINFORMATION, e.error.text, ['OK']);
        });
    } else {
      this.commonService.showAlert(messages.ALERTTITLINFORMATION, messages.PLEASECONNECTTONETWORK, ['OK']);
    }
  }
 
  async getGarageMappedUsers() {
    if (navigator.onLine) {
      var self = this;
      this.usersList = [];
      const loader = await this.commonService.showLoading(messages.LOADINGGARAGES);
      this.garageService
        .getGarageMappedUsers(this.garageData.ControlItemID)
        .then((data: any) => {
          loader.dismiss();
          if (data.length > 0) {
            data.forEach(function (element, index, object) {
              if (element.IsActive) {
                element.Status = 'Active';
              } else {
                element.Status = 'InActive';
              }
              element.fullName = element.LastName + ", " + element.FirstName;
            });
            this.usersList = data;
            this.userListOriginal = data;

          }
        })
        .catch((e) => {
          loader.dismiss();
        });
    } else {
      this.commonService.showAlert(messages.ALERTTITLINFORMATION, messages.PLEASECONNECTTONETWORK, ['OK']);
    }
  }
  async searchBar(evt) {
    this.tempUsersList = this.userListOriginal;
    this.searchTextVal = evt.srcElement.value;
    this.filterList();
  }
 
   filterList() {
    this.tempUsersList = this.userListOriginal;

    if (this.searchTextVal != '') {
      this.tempUsersList = this.tempUsersList.filter(current => {
        return (current.FirstName.toLowerCase().indexOf(this.searchTextVal.toLowerCase()) > -1 ||
          current.LastName.toLowerCase().indexOf(this.searchTextVal.toLowerCase()) > -1 ||
          current.Email.toLowerCase().indexOf(this.searchTextVal.toLowerCase()) > -1 ||
          current.LastName.toLowerCase().indexOf(this.searchTextVal.toLowerCase()) > -1
        );
      });
    }
    this.usersList = this.tempUsersList;
  }
  toggleFreeGarageChange(event) {
    this.garageData.IsFreezeGarage = event.detail.checked;
    if(this.garageData.IsFreezeGarage != this.tempOldGarageData.IsFreezeGarage){
      this.showAlertGarageFreezeDescription();
    }
    
  }
  toggleIsCommericalChange(event) {
    this.garageData.IsCommercial = event.detail.checked;
    
  }
  async showAlertGarageFreezeDescription() {
    const alert = await this.alertCtrl.create({
      header: 'Enter the reason',
      inputs: [
        {
          name: 'description',
          type: 'text',
          placeholder: 'Reason',

        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
            this.garageData.IsFreezeGarage = !this.garageData.IsFreezeGarage;
          }
        }, {
          text: 'Ok',
          handler: (data) => {
            if (data.description.length > 0) {
              console.log('Confirm Ok ' + data.description);
              this.garageData.Description = data.description;
            } else {
              this.commonService.presentToast(messages.FREEZEDESCRIPTIOMMANDATORY);
              return false;
            }

          }
        }
      ]
    });

    await alert.present();
  }
  // onOpenGarageClick() {
  //   this.garageCommands('Open');
  // }
  // onCloseGarageClick() {
  //   this.garageCommands('Close');
  // }
  // onStopGarageClick() {
  //   this.garageCommands('Stop');
  // }
  // private async garageCommands(commandName) {
  //   if (navigator.onLine) {
  //     let self = this;
  //     var particle = new Particle();
  //     var fnPr = particle.callFunction({ deviceId: this.garageData.ControlItemName, name: commandName, argument: 'D0:HIGH', auth: this.globals.userInfo.accessToken });//this.deviceInfo.id
  //     fnPr.then(
  //       function (data) {
  //         //self.loader.dismiss();
  //         console.log('Function called succesfully:', data);
  //         var customMessage = '';
  //         if (commandName == 'Open') {
  //           customMessage = 'Succesfully Opened'; 
  //         } else if (commandName == 'Close') {
  //           customMessage = 'Succesfully Closed';
  //         } else if (commandName == 'Stop') {
  //           customMessage = 'Succesfully Stopped';
  //         }


  //         self.saveActivityHistory(commandName, customMessage);

  //       }, function (err) {
  //         //self.loader.dismiss();
  //         console.log('An error occurred:', err);
  //       });
  //   } else {
  //     this.commonService.showAlert(messages.ALERTTITLINFORMATION, messages.PLEASECONNECTTONETWORK, ['OK']);
  //   }
  // }
  // saveActivityHistory(commandName, customMessage) {
  //   const body = {UserID:this.globals.userInfo.LoggedInUserID, ControlItemID:this.garageData.ControlItemID, ActivityName:commandName.toUpperCase(), LoggedInEmail:this.globals.userInfo.userName}
  //   this.garageService.saveActivityHistory(body).then(data => {
  //     this.commonService.presentToast(customMessage);
  //   }, error => {

  //     this.commonService.showAlert(messages.SERVERERRORTITLE, messages.SERVERERROR, ['OK']);
  //   });

  // }
}
